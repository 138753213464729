
import { Vue, Component } from "vue-property-decorator";
import { UserService } from "@/api/UserServiceApi";
import InputField from "@/components/InputField.vue";
import user from "@/store/modules/UserModule";

@Component({
  components: {
    InputField
  }
})
export default class ForgotPassword extends Vue {
  email: string = "";

  async sendPasswordReset() {
    if (this.email) {
      await UserService.SendPasswordResetEmail(this.email, this.$i18n.locale);
      this.$swal.fire({
        titleText: this.$t("MODAL.PASSWORD_RESET.TITLE").toString(),
        text: this.$t("MODAL.PASSWORD_RESET.SUB_TITLE").toString(),
        confirmButtonText: "Ok",
        icon: "info"
      });
    }
  }
}
